import React from "react"
import { graphql } from "gatsby"
import KEEP from "/content/assets/sponsors/KEEP.png"
import Churchill from "/content/assets/sponsors/Churchill.jpg"
import Keeneland from "/content/assets/sponsors/Keeneland.png"
import RFEducation from "/content/assets/sponsors/Race-for-education.jpg"
import UofL from "/content/assets/sponsors/UofL-equine-industry-program.jpg"
import Workforce from "/content/assets/sponsors/Workforce-Center.png"
import KYHC from "/content/assets/sponsors/KY-Horse-Council.jpg"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"

const Initiative = ({ data }) => {
  return (
    <Layout>
    <Helmet>
      <title>Initiatives</title>
    </Helmet>
    <section class="hero is-halfheight hero-banner" id="initiative-hero">
          <div class="hero-body">
          </div>
    </section>      
    <main>
        <h1>Equine Workforce Initiative</h1>
        <h3>The equine workforce struggle in Kentucky</h3>
                <p>It is a well-known fact that Kentucky is the horse capitol of the world, but it is the people within the industry that have elevated it to that status, and who will keep it there. Like many states and industries around the country, the equine industry in Kentucky is struggling to find qualified workers to fill its workforce ranks. Because of that, KEEP has teamed up with the Kentucky Chamber of Commerce Workforce Center to address the shortage of qualified equine workers on a state level. Through employer led work groups the industry is getting the facts straight from the horse’s mouth so that community and education partners may be better informed of the needs of industry employers.</p>
        <div class="columns is-centered">
          <iframe width="500" height="281" src="https://www.youtube.com/embed/bobhNuRsgdE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <p>Results that will position Kentucky’s equine industry and its workforce for the future</p>

<p>The Equine Workforce Initiative continues to yield a number of industry-changing programs and initiatives. Click to read more about what is currently underway:</p>
<ul>
<li><a href="/initiatives/horseman-apprenticeship">Horseman Apprenticeship</a></li>
<li><a href="/initiatives/workforce-readiness-and-reentry-program">Department of Corrections Workforce Readiness and Reentry Program</a></li>
<li><a href="/initiatives/engagement-of-emerging-populations">Engagement of Emerging Populations</a></li>
<li><a href="/initiatives/career-pathway-documents">Equine Career Pathway Documents</a></li>
<li><a href="/initiatives/second-fair-chance-employment">Second/Fair Chance Employment</a></li>
<li><a href="/initiatives/equine-bus-to-business">Equine Bus to Business</a></li>
<li><a href="/initiatives/vocational-training-for-those-in-recovery">Vocational Training for Those in Recovery</a></li>
<p>This work is built on the input of five industry collaboratives</p>
</ul>
<p>The work and results of the Equine Workforce Initiative all stem from the five collaboratives that have informed and built the responses to the critical issues facing Kentucky’s horse industry. Click to read more about each of the five collaboratives:</p>
<ul>
<li><a href="/collaborative/non-racing-farm-collaborative">Non-Racing Farm Collaborative</a></li>
<li><a href="/collaborative/thoroughbred-farm-collaborative">Thoroughbred Farm Collaborative</a></li>
<li><a href="/collaborative/equine-transportation-collaborative">Equine Transportation Collaborative</a></li>
<li><a href="/collaborative/equine-veterinary-clinic-collaborative">Equine Veterinary Clinic Collaborative</a></li>
</ul>
<h2>Sponsors of the Equine Workforce Initiative</h2>
<p>Without the support and collaboration from the sponsors of the Equine Workforce Initiative, the KEEP Foundation would not have been able to embark on this critical project. Through donations of both time and finances, these entities have helped create a culture shift in the equine industry that focuses on career education, community relations, and the growth of the industry as a whole through workforce development. The champion supporters of the Equine Workforce Initiative include:</p>
<div class="sponsors">
  <div class="sponsor"><a href="http://www.horseswork.com/"><img src={KEEP} alt="KEEP" title="KEEP" /></a></div>
  <div class="sponsor"><a href="http://www.raceforeducation.org/"><img src={RFEducation} alt="Race for Education" title="Race for Education" /></a></div>
  <div class="sponsor"><a href="https://www.kychamber.com/WorkforceCenter"><img src={Workforce} alt="Workforce Center" title="Workforce Center" /></a></div>
  <div class="sponsor"><a href="https://www.keeneland.com/"><img src={Keeneland} alt="Keeneland" title="Keeneland" /></a></div>
  <div class="sponsor"><a href="https://www.churchilldowns.com/"><img src={Churchill} alt="Churchill" title="Churchill" /></a></div>
  <div class="sponsor"><a href="https://business.louisville.edu/academics-programs/equine/"><img src={UofL} alt="UofL" title="UofL" /></a></div>
  <div class="sponsor"><a href="https://kentuckyhorse.org/"><img src={KYHC} alt="KY Horse Council" title="KY Horse Council" /></a></div>
</div>

<h2>To Learn More</h2>
<p><a href="mailto:lmays@kychamber.com">Contact Laurie Mays</a>, Equine Talent Pipeline Project Manager<br />
Visit the Kentucky Chamber of Commerce Workforce Center’s <a href="https://www.kychamber.com/kentuckystpm">Talent Pipeline Project website.</a><br />
View the <a rel="noreferrer" href="https://drive.google.com/file/d/1lJr4hXLjYqN2LP9RsK8oeO4nsP3Fto46/view" target="_blank">progress report</a> of the success of the first three years of the Talent Pipeline Project.</p>

    </main>
    </Layout>
  )
}

export default Initiative

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`